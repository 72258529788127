<template>
	<div>
		<h3>{{ kindergartenInfo.org_name }} / {{ kindergartenInfo.name }}</h3>

		<v-divider class="my-4"></v-divider>

		<v-data-table
			:headers="requestHeaders"
			:items="requestList"
			:items-per-page="15"
			:loading="isLoading"
			@click:row="handleClick"
			class="elevation-1 request_list">
		</v-data-table>
	</div>
</template>

<script>
	export default {
        mounted() {
            this.getData();
        },
		data: () => ({
			isLoading: true,
			kindergartenInfo: {},
			requestHeaders: [],
			requestList: []
		}),
		methods: {
			handleClick(item) {
				this.$router.push({ path: '/department/kindergarten/'+this.$route.params.id+'/group/'+item.id });
			},
			async getData() {
				await this.$http.get('/kindergarten/'+this.$route.params.id).then((response) => {
					this.requestHeaders = response.data.data.kindergartenGroupList.headers;
					this.requestList = response.data.data.kindergartenGroupList.list;
					this.kindergartenInfo = response.data.data.kindergartenInfo;
					this.isLoading = false;
				});
			}
			
		}
  }
</script>

<style scoped>

</style>